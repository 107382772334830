

import React from "react";
import { Link } from "react-router-dom";



const Footer = () => {
  return (
    <div style={{ backgroundColor: '#0F2A8E'}}>
      <div>

        <div style={{ display: 'flex', padding: 50 }}>
        </div>
      </div>
    </div>
  );
};

export default Footer;
